
import Vue, { PropType } from 'vue';

import TestRuleItem from '@/components/ui/TestRules/TestRuleItem.vue';
import TestRuleItemSkeleton from '@/components/ui/TestRules/TestRuleItemSkeleton.vue';

import { AtomType } from '@/domains/atom';
import { PlayerSessionStatus } from '@/domains/common';

interface IItem {
  title: string;
  description: string;
  src: string;
  show: boolean;
  dataTest: string;
  dataTestLabel?: string;
}

export default Vue.extend({
  name: 'TestRules',
  components: {
    TestRuleItem,
    TestRuleItemSkeleton,
  },
  props: {
    time: {
      type: Number,
      required: true,
    },
    atomType: {
      type: String,
      required: true,
    },
    freeFlow: {
      type: Boolean,
      default: false,
    },
    maxAttempts: {
      type: Number,
      default: 0,
    },
    currentAttempt: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    playerSessionStatus: {
      type: String as PropType<PlayerSessionStatus>,
      default: '',
    },
  },

  computed: {
    timePrepared(): string {
      let minutes = Math.floor(this.time / 60);
      const seconds = this.time - (minutes * 60);

      if (minutes >= 60) {
        const hours = Math.floor(this.time / 60 / 60);
        minutes = Math.round((this.time % 3600) / 60);
        if (minutes === 0) {
          return this.$tc('pluralized.hours', hours);
        }
        return `${this.$tc('pluralized.hours', hours)} ${this.$tc('pluralized.minutes', minutes)}`;
      }

      if (minutes && !seconds) {
        return this.$tc('pluralized.minutes', minutes);
      }

      if (!minutes && seconds) {
        return this.$tc('pluralized.seconds', seconds);
      }

      return `${this.$tc('pluralized.minutes', minutes)} ${this.$tc('pluralized.seconds', seconds)}`;
    },

    isSessionActive(): boolean {
      return this.playerSessionStatus === PlayerSessionStatus.ACTIVE;
    },

    items(): IItem[] {
      const quizAttemptsDifference = this.isSessionActive
        ? this.maxAttempts - this.currentAttempt + 1 // +1 т.к. сессия еще не завершена и текущая попытка еще активна
        : this.maxAttempts - this.currentAttempt;
      const quizAttempts = quizAttemptsDifference > 0 ? quizAttemptsDifference : 0;
      return [
        // MULTILEVELTEST
        {
          title: this.$t('TestRules.multileveltest.time.title', { time: this.timePrepared }),
          description: this.$t('TestRules.multileveltest.time.description'),
          // eslint-disable-next-line global-require
          src: require('@/assets/img/alarm-rules.png'),
          show: this.showItem(AtomType.MULTILEVELTEST) && this.time > 0,
          dataTest: 'multileveltest-rule-item',
          dataTestLabel: 'multileveltest-rule-time',
        },
        {
          title: this.$t('TestRules.multileveltest.timeUnless.title'),
          description: this.$t('TestRules.multileveltest.timeUnless.description'),
          // eslint-disable-next-line global-require
          src: require('@/assets/img/alarm-rules.png'),
          show: this.showItem(AtomType.MULTILEVELTEST) && this.time <= 0,
          dataTest: 'multileveltest-rule-item',
          dataTestLabel: 'multileveltest-rule-unless-time',
        },
        {
          title: this.$t('TestRules.multileveltest.questions.title'),
          description: this.$t('TestRules.multileveltest.questions.description'),
          // eslint-disable-next-line global-require
          src: require('@/assets/img/kube.png'),
          show: this.showItem(AtomType.MULTILEVELTEST),
          dataTest: 'multileveltest-rule-item',
          dataTestLabel: 'multileveltest-rule-questions',
        },
        {
          title: this.$t('TestRules.multileveltest.level.title'),
          description: this.$t('TestRules.multileveltest.level.description'),
          // eslint-disable-next-line global-require
          src: require('@/assets/img/crown.png'),
          show: this.showItem(AtomType.MULTILEVELTEST),
          dataTest: 'multileveltest-rule-item',
          dataTestLabel: 'multileveltest-rule-level',
        },
        // QUIZ
        {
          title: this.$t('TestRules.quiz.time.title', { time: this.timePrepared }),
          description: this.$t('TestRules.quiz.time.description'),
          // eslint-disable-next-line global-require
          src: require('@/assets/img/alarm-rules.png'),
          show: this.showItem(AtomType.QUIZ) && this.time > 0,
          dataTest: 'quiz-rule-item',
          dataTestLabel: 'quiz-rule-time',
        },
        {
          title: this.$t('TestRules.quiz.timeUnless.title'),
          description: this.$t('TestRules.quiz.timeUnless.description'),
          // eslint-disable-next-line global-require
          src: require('@/assets/img/alarm-rules.png'),
          show: this.showItem(AtomType.QUIZ) && this.time <= 0,
          dataTest: 'quiz-rule-item',
          dataTestLabel: 'quiz-rule-unless-time',
        },
        {
          title: this.maxAttempts === 1
            ? this.$tc('TestRules.quiz.test.onlyOneAttempt.title', quizAttempts)
            : this.$tc('TestRules.quiz.test.manyAttempts.title', quizAttempts),
          description: this.maxAttempts === 1
            ? this.$tc('TestRules.quiz.test.onlyOneAttempt.description', quizAttempts)
            : this.$tc('TestRules.quiz.test.manyAttempts.description', quizAttempts),
          // eslint-disable-next-line global-require
          src: require('@/assets/img/star.png'),
          show: this.showItem(AtomType.QUIZ),
          dataTest: 'quiz-rule-item',
          dataTestLabel: 'quiz-rule-test',
        },
        {
          title: this.$t('TestRules.quiz.questions.title'),
          description: this.$t('TestRules.quiz.questions.description'),
          // eslint-disable-next-line global-require
          src: require('@/assets/img/kube.png'),
          show: this.showItem(AtomType.QUIZ) && !this.freeFlow,
          dataTest: 'quiz-rule-item',
          dataTestLabel: 'quiz-rule-questions',
        },
        {
          title: this.$t('TestRules.quiz.questionsFree.title'),
          description: this.$t('TestRules.quiz.questionsFree.description'),
          // eslint-disable-next-line global-require
          src: require('@/assets/img/kube.png'),
          show: this.showItem(AtomType.QUIZ) && this.freeFlow,
          dataTest: 'quiz-rule-item',
          dataTestLabel: 'quiz-rule-free-questions',
        },
      ];
    },
  },
  methods: {
    showItem(type: string) {
      return type === this.atomType;
    },
  },
});
