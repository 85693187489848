
import {
  ref, computed, defineComponent, onMounted, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import { IBreadcrumb, PlayerSessionStatus } from '@/domains/common';
import { Names } from '@/plugins/vue-router';
import { useI18n } from '@/plugins/vue-i18n';

import { useAssignment } from '@/domains/assignment/composables';
import { useCatalogueAtom } from '@/domains/atom/composables';
import { AtomType } from '@/domains/atom';
import { useQuizSession } from '@/domains/quiz/composables/useQuizSession';
import EmptyBlock from '@/components/ui/EmptyBlock.vue';
import TestRules from '@/components/ui/TestRules';
import QuizIntroDescriptionPanel from '@/components/quiz/QuizIntroDescriptionPanel.vue';
import { useDi } from '@/plugins';
import { IQuizStep } from '@/domains/quiz';
import { getRunTestAgainButtonTimePart } from '@/helpers/time/blockingRunQuizButton';
import { BasePage } from '@/components/ui/BasePage';
import AtomPlayerSidebar from '@/components/ui/AtomSidebar';

export default defineComponent({
  name: 'AssignmentQuizIntro',

  components: {
    EmptyBlock,
    TestRules,
    QuizIntroDescriptionPanel,
    BasePage,
    AtomPlayerSidebar,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { playerSessionId } = toRefs(props);

    const { t } = useI18n();
    const router = useRouter();
    const di = useDi();

    const isLoading = ref<boolean>(true);
    const isError = ref<boolean>(false);

    const {
      isLoading: isAssignmentLoading,
      fetchAssignmentBySession: fetchAssignment,
      assignment,
      isSelfAssignment,
      catalogueAtomVersionId,
    } = useAssignment({ playerSessionId, atomType: AtomType.QUIZ });

    const {
      fetchAtom,
      atom,
    } = useCatalogueAtom({ atomId: catalogueAtomVersionId });

    const {
      session,

      testTimeoutInSeconds,
      timeBetweenAttempts,
      hasNextAttemptsTimeFlag,

      isSessionStarting,
      isSessionUpdating,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,

      fetchSession,
      runSession,
    } = useQuizSession({ playerSessionId });

    const buttonText = computed<string>(() => {
      const str = getRunTestAgainButtonTimePart(timeBetweenAttempts.value);

      switch (session.value!.playerSessionStatus) {
        case PlayerSessionStatus.ACTIVE:
          return t('QuizIntro.buttonText.continue');
        case PlayerSessionStatus.NEW:
        default:
          return str.length
            ? t('QuizIntro.buttonText.start.time', { time: str })
            : t('QuizIntro.buttonText.start.withoutTime');
      }
    });

    const onClickUpdateSessionHandler = async () => {
      const ON_RUN_STEP_MAP: Record<IQuizStep, Names> = {
        [IQuizStep.INTRO]: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_INTRO,
        [IQuizStep.PLAYER]: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_PLAYER,
        [IQuizStep.RESULT]: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_RESULT,
        [IQuizStep.ANSWERS]: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_ANSWERS,
      };

      try {
        const { quizStep } = await runSession();

        await router.push({
          name: ON_RUN_STEP_MAP[quizStep],
          params: {
            playerSessionId: String(playerSessionId.value),
          },
        });
      } catch (e: any) {
        di.notify.error({ content: e.message });
      }
    };

    const breadcrumbs = computed<IBreadcrumb[]>(() => [
      {
        text: t('LearningTracks.title'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-program-link',
      },
      {
        text: atom.value?.name ?? '',
        'data-test-label': 'program-name-link',
        disabled: true,
      },
    ]);

    const step = computed(() => ({
      atomType: AtomType.QUIZ,
      name: atom.value?.name,
      isCompleted: isSessionCompleted.value,
      id: atom.value?.id ?? '',
    }));

    onMounted(async () => {
      Promise.all([
        fetchSession(),
        fetchAssignment().then(fetchAtom),
      ])
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    return {
      isLoading,
      isError,

      session,

      testTimeoutInSeconds,
      hasNextAttemptsTimeFlag,
      buttonText,

      step,
      atom,
      assignment,
      isAssignmentLoading,
      isSelfAssignment,

      isSessionStarting,
      isSessionUpdating,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,

      breadcrumbs,
      onClickUpdateSessionHandler,

      AtomType,
    };
  },
});
