import { PlayerSessionId } from '@/domains/common';
import { Names } from '@/plugins/vue-router';
import { isEmpty } from '@/helpers';

type IParams = Record<string, string | number | undefined>;

interface IQuizBaseParams extends IParams {
  playerSessionId?: PlayerSessionId;
  stepId?: string;
  trackSessionId?: string;
  assignmentId?: string;
}

function paramsToString(params: IParams): Record<string, string> {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (!isEmpty(value)) {
      Reflect.set(acc, key, value);
    }

    return acc;
  }, {});
}

export interface IQuizWrapperParams extends IQuizBaseParams {}
export function toQuizWrapper(params: IQuizWrapperParams = {}) {
  return {
    name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ,
    params: paramsToString(params),
  };
}

export interface IQuizViewParams extends IQuizBaseParams {}
export function toQuizView(params: IQuizViewParams = {}) {
  return {
    name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_VIEW,
    params: paramsToString(params),
  };
}

export interface IQuizQuestionParams extends IQuizBaseParams {
  questionId?: string;
}
export function toQuizQuestion(params: IQuizQuestionParams = {}) {
  return {
    name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_QUESTION,
    params: paramsToString(params),
  };
}

export interface IQuizResultParams extends IQuizBaseParams {}
export function toQuizResult(params: IQuizResultParams = {}) {
  return {
    name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_RESULT,
    params: paramsToString(params),
  };
}

export interface IQuizResultViewParams extends IQuizBaseParams {}
export function toQuizResultView(params: IQuizResultViewParams = {}) {
  return {
    name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_RESULT_VIEW,
    params: paramsToString(params),
  };
}
