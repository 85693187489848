import { DateTimeISO8601, PlayerSessionStatus } from '../common';
import { IQuiz, IQuizResult } from './types';

export default class Quiz implements IQuiz {
  playerSessionStatus: PlayerSessionStatus;

  name: string;

  description: string | null;

  questionsCount: number;

  testTimeout: number;

  freeFlow: boolean;

  results: IQuizResult | null;

  maxAttempts: number;

  attemptsCount: number;

  nextAttemptAt: DateTimeISO8601;

  constructor(props: Partial<IQuiz> = {}) {
    this.playerSessionStatus = props.playerSessionStatus ?? PlayerSessionStatus.UNKNOWN;
    this.name = props.name ?? '';
    this.description = props.description ?? '';
    this.questionsCount = props.questionsCount ?? 0;
    this.testTimeout = props.testTimeout ?? NaN;
    this.freeFlow = props.freeFlow ?? false;
    this.results = props.results ?? null;
    this.maxAttempts = props.maxAttempts ?? 0;
    this.attemptsCount = props.attemptsCount ?? 0;
    this.nextAttemptAt = props.nextAttemptAt ?? '';
  }
}
