import { render, staticRenderFns } from "./TestRules.vue?vue&type=template&id=907603b6&"
import script from "./TestRules.vue?vue&type=script&lang=ts&"
export * from "./TestRules.vue?vue&type=script&lang=ts&"
import style0 from "./TestRules.vue?vue&type=style&index=0&id=907603b6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports