import { QuizQuestionType } from '../types';

export default function normalizeQuestionType(questionType: QuizQuestionType) {
  switch (questionType) {
    case QuizQuestionType.POLY:
      return 'poly';
    case QuizQuestionType.HASH:
      return 'hash';
    case QuizQuestionType.ORDER:
      return 'order';
    case QuizQuestionType.OPEN:
      return 'open';
    default:
      return 'default';
  }
}
