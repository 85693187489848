
import Vue from 'vue';
import Skeleton from '@/components/ui/Skeleton.vue';

export default Vue.extend({
  name: 'TestRuleItemSkeleton',
  components: {
    Skeleton,
  },
});
