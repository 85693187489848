
import { defineComponent, computed, Ref } from 'vue';
import { useI18n } from '@/plugins/vue-i18n';

export default defineComponent({
  name: 'QuizIntroDescriptionPanel',
  props: {
    questionsCount: {
      type: Number,
      default: 0,
    },
    quizTime: {
      type: Number,
      default: 0,
    },
    quizCurrentAttempt: {
      type: Number,
      required: true,
    },
    quizMaxAttempt: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { t, tc } = useI18n();

    const quizTimeText: Ref<string> = computed(() => {
      if (props.quizTime > 60) {
        const hours = Math.floor(props.quizTime / 60);
        const minutes = Math.round(props.quizTime % 60);
        return `${tc('pluralized.hours', hours)} ${tc('pluralized.minutes', minutes)}`;
      }

      if (props.quizTime > 0) {
        return tc('pluralized.minutes', props.quizTime);
      }

      return t('QuizIntroDescriptionPanel.unlimiteTime');
    });
    const quizAttemptsText: Ref<string> = computed(() => {
      if (props.quizCurrentAttempt === 0 && props.quizMaxAttempt > 0) {
        return tc('QuizIntroDescriptionPanel.attempts', props.quizMaxAttempt);
      }

      if (props.quizCurrentAttempt > 0 && props.quizMaxAttempt > 0) {
        return t('QuizIntroDescriptionPanel.attemptsRange', {
          current: props.quizCurrentAttempt,
          max: props.quizMaxAttempt,
        });
      }

      return '';
    });

    return {
      quizTimeText,
      quizAttemptsText,
    };
  },
});
