
import { defineComponent, PropType } from 'vue';

type ISkeletonType =
  | 'actions'
  | 'article'
  | 'avatar'
  | 'button'
  | 'card'
  | 'card-avatar'
  | 'card-heading'
  | 'chip'
  | 'date-picker'
  | 'date-picker-options'
  | 'date-picker-days'
  | 'heading'
  | 'image'
  | 'list-item'
  | 'list-item-avatar'
  | 'list-item-two-line'
  | 'list-item-avatar-two-line'
  | 'list-item-three-line'
  | 'list-item-avatar-three-line'
  | 'paragraph'
  | 'sentences'
  | 'table'
  | 'table-heading'
  | 'table-thead'
  | 'table-tbody'
  | 'table-row-divider'
  | 'table-row'
  | 'table-cell'
  | 'table-tfoot'
  | 'text';

export default defineComponent({
  name: 'Skeleton',
  props: {
    type: {
      type: String as PropType<ISkeletonType>,
      require: true,
      default: 'text',
    },
    types: {
      type: Object,
      default: () => ({}),
    },
  },
});
